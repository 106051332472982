import { Box, IconButton } from '@mui/material'
import { useCallback, useState } from 'react'
import { ClosePanel, UserPanelContainer } from '../styles/user-panel'
import TabPanel from './tab-panels/tab-panel'
import { StandardTab, StandardTabs } from '../styles/tabs'
import { Close } from '@mui/icons-material'
import { StandardDrawer } from '../styles/dialog'
import ManageInfoPanel from './tab-panels/manage-info-panel'
import ManageUsersPanel from './tab-panels/manage-users-panel'
import { useTranslation } from 'react-i18next'

const ProjectPanel = ({ handleClose }: ProjectsPanelProps) => {
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const [changed, setChanged] = useState<boolean>(false)
    const { t } = useTranslation()

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    const handleChange = useCallback(
        (_: React.SyntheticEvent, newValue: number) => {
            setSelectedTab(newValue)
        },
        [setSelectedTab]
    )

    const handleClosing = useCallback(() => {
        handleClose(changed)
    }, [changed, handleClose])

    return (
        <StandardDrawer anchor='right' open={true} onClose={handleClosing}>
            <UserPanelContainer>
                <ClosePanel>
                    <IconButton onClick={handleClosing}>
                        <Close />
                    </IconButton>
                </ClosePanel>
                <Box>
                    <Box>
                        <StandardTabs value={selectedTab} onChange={handleChange} aria-label='manage account tabs'>
                            <StandardTab label={t('projects.info')} {...a11yProps(0)} />
                            <StandardTab label={t('projects.users')} {...a11yProps(1)} />
                        </StandardTabs>
                    </Box>

                    <TabPanel value={selectedTab} index={0}>
                        <ManageInfoPanel handleChanged={setChanged} handleClosing={handleClosing} />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        <ManageUsersPanel handleChanged={setChanged} handleClosing={handleClosing} />
                    </TabPanel>
                </Box>
            </UserPanelContainer>
        </StandardDrawer>
    )
}

export default ProjectPanel
