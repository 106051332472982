import { CircularProgress } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { fetchSeatAssignments } from '../../redux/actions/subscriptionsSlice'
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks'
import {
    selectSeatAssignments,
    selectSeatAssignmentsError,
    selectSeatAssignmentsStatus,
} from '../../redux/selectors/subscriptions-selector'
import { Header, HeaderDivider } from '../../styles/header'
import { SectionContent } from '../../styles/section-content'
import CustomTable from '../../components/custom-table'
import { SpacingM } from '../../styles/spacing'
import { editionHasMultipleSeats } from '../../utils/utils'
import { useTranslation } from 'react-i18next'
import { RpcError } from 'grpc-web'

const MySubscriptionsSection = () => {
    const dispatch = useAppDispatch()
    const seatAssignments: SeatAssignment[] = useAppSelector(selectSeatAssignments)
    const status: LoadStatus = useAppSelector(selectSeatAssignmentsStatus)
    const error: RpcError = useAppSelector(selectSeatAssignmentsError)
    const { t } = useTranslation()

    useEffect(() => {
        if (status === 'not_loaded') {
            dispatch(fetchSeatAssignments())
        }
    }, [dispatch, status])

    const loaded: boolean = useMemo((): boolean => status === 'loaded' || status === 'failed', [status])

    useEffect((): void => {
        document.title = `${t('subscriptions.mySubscriptions')} – ${process.env.REACT_APP_TITLE}`
    }, [t])

    const hasOptionalProducts: boolean = useMemo(
        (): boolean =>
            seatAssignments
                .map(seat => {
                    return seat.subscriptionline.optionalproductsList.length
                })
                .filter(item => item).length > 0,
        [seatAssignments]
    )

    const hasMultipleSubscriptions: string[] = useMemo((): string[] => {
        return editionHasMultipleSeats(seatAssignments)
    }, [seatAssignments])

    const columnsProducts: ColumnData[] = useMemo((): ColumnData[] => {
        const cols = [
            { id: 'subscription', name: t('subscriptions.subscription') },
            { id: 'edition', name: t('subscriptions.edition') },
            { id: 'optional-products', name: t('subscriptions.optionalProducts') },
            { id: 'seats', name: t('subscriptions.seats') },
            { id: 'expiry-date', name: t('subscriptions.expiration') },
        ]
        if (!hasOptionalProducts) {
            cols.splice(2, 1)
        }
        if (!hasMultipleSubscriptions.length) {
            cols.splice(0, 1)
        }
        return cols
    }, [hasMultipleSubscriptions, hasOptionalProducts, t])

    const rowsProducts: RowData[] = useMemo((): RowData[] => {
        return seatAssignments
            .filter((seat: SeatAssignment) => seat.state === 1)
            .map((seat: SeatAssignment) => {
                const cells = [
                    seat.subscriptionline.name,
                    seat.subscriptionline.product.name,
                    seat.subscriptionline.optionalproductsList.map(item => item.name).join(', '),
                    seat.numberofseats,
                    new Date(seat.expirationdate).toLocaleDateString(),
                ]
                if (!hasOptionalProducts) {
                    cells.splice(2, 1)
                }
                if (!hasMultipleSubscriptions.length) {
                    cells.splice(0, 1)
                }
                return {
                    id: seat.id,
                    cells,
                }
            })
    }, [seatAssignments, hasMultipleSubscriptions, hasOptionalProducts])

    const columnsActivations: ColumnData[] = useMemo((): ColumnData[] => {
        return [
            { id: 'edition', name: t('subscriptions.edition') },
            { id: 'key', name: t('subscriptions.keyId') },
            { id: 'hostname', name: t('subscriptions.device') },
        ]
    }, [t])

    const rowsActivations: RowData[] = useMemo((): RowData[] => {
        const rows: RowData[] = []
        seatAssignments
            .filter((seat: SeatAssignment): boolean => seat.state === 1)
            .forEach((seat: SeatAssignment): void =>
                seat.usedactivationsList
                    .map((activation: InUseActivationInfo): RowData => {
                        return {
                            id: seat.id,
                            cells: [seat.subscriptionline.product.name, activation.key, activation.hostname],
                        }
                    })
                    .forEach((activation: RowData) => rows.push(activation))
            )
        return rows
    }, [seatAssignments])

    return (
        <SectionContent>
            <Header>{t('subscriptions.mySubscriptions')}</Header>
            <HeaderDivider />
            {!loaded && <CircularProgress color='inherit' />}
            {loaded && (
                <>
                    <CustomTable columns={columnsProducts} rows={rowsProducts} error={error} />
                    <SpacingM />
                    <CustomTable
                        title={t('subscriptions.activations')}
                        columns={columnsActivations}
                        rows={rowsActivations}
                        error={error}
                    />
                </>
            )}
        </SectionContent>
    )
}

export default MySubscriptionsSection
