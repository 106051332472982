import { styled } from '@mui/material'
import { FlexColumn, FlexRow } from './flex'

export const ProfileContainer = styled(FlexRow)({})

export const ProfileForm = styled(FlexColumn)(({ theme }) => ({
    width: 218,
    marginLeft: 32,
    paddingLeft: 32,
    borderLeftColor: theme.palette.custom.lightGrey.main,
    borderLeftStyle: 'solid',
    borderLeftWidth: 2,

    '.MuiTextField-root': {
        marginBottom: 15,
    },
}))

export const ProfileHeader = styled(FlexRow)({
    alignItems: 'center',
    marginBottom: 32,

    '& > div': {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },

    '& svg': {
        marginRight: 15,
        fontSize: 32,
    },
})

export const ProfileButtons = styled(FlexRow)({
    justifyContent: 'space-between',
    gap: 20,
})

export const PasswordForm = styled(FlexColumn)(({ theme }) => ({
    width: 354,
    gap: 16,
}))
