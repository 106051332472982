import { Outlet } from 'react-router-dom'
import SectionSidebarProfile from '../components/section-sidebar-profile'

const Profile: React.FC = () => {
    return (
        <>
            <SectionSidebarProfile />
            <Outlet />
        </>
    )
}

export default Profile
