import { styled } from '@mui/material'

export const FlexRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
})

export const FlexColumn = styled('div')({
    display: 'flex',
    flexDirection: 'column',
})

export const FlexRowCenter = styled(FlexRow)({
    alignItems: 'center',
    justifyContent: 'center',
})

export const FlexColumnCenter = styled(FlexColumn)({
    alignItems: 'center',
    justifyContent: 'center',
})

export const VerticalForm = styled('form')({
    display: 'flex',
    flexDirection: 'column',
})
