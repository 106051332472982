import {
    AdminPanelSettingsOutlined,
    CasesOutlined,
    DescriptionOutlined,
    KeyboardArrowLeftRounded,
    KeyboardArrowRightRounded,
    ListAltRounded,
    LogoutOutlined,
    PersonRounded,
    QuestionAnswerOutlined,
} from '@mui/icons-material'
import { useCallback, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Sidebar, SidebarHeader, SidebarMenu, SidebarMenuItem, SidebarToggle } from '../styles/sidebar'
import logo from '../assets/logo.svg'
import { canAccessAdminSite } from '../utils/utils'
import { useAppSelector } from '../redux/hooks/hooks'
import { selectAccessToken, selectIdToken, selectRefreshToken } from '../redux/selectors/auth-selector'
import { selectProfile } from '../redux/selectors/profile-selector'
import { signOut } from '../grpc/api'
import { ConfirmSignOutDialog } from '../utils/dialogs'
import Dialog from './dialog'
import BuildVersion from './build-version'
import { Link } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { selectProjects } from '../redux/selectors/projects-selector'

type classNameProps = { isActive: boolean; isPending: boolean }

const SideBar: React.FC = () => {
    const [open, setOpen] = useState<boolean>(true)
    const [showDialog, setShowDialog] = useState<boolean>(false)

    const profile: Profile = useAppSelector(selectProfile)
    const projects: MyProject[] = useAppSelector(selectProjects)
    const accessToken: string = useAppSelector(selectAccessToken)
    const refreshToken: string = useAppSelector(selectRefreshToken)
    const idToken: string = useAppSelector(selectIdToken)

    const { t } = useTranslation()

    const adminPortalUrl = useMemo((): string => {
        return `${process.env.REACT_APP_ADMIN_URL}/users?token=${accessToken}&refresh_token=${refreshToken}&id_token=${idToken}`
    }, [accessToken, refreshToken, idToken])

    const forumUrl = useMemo((): string => {
        return `${process.env.REACT_APP_FORUM_URL}`
    }, [])

    const docsUrl = useMemo((): string => {
        return `${process.env.REACT_APP_DOCS_URL}`
    }, [])

    const activeClassname = useCallback(({ isActive }: classNameProps) => (isActive ? 'active' : undefined), [])

    const handleToggle = useCallback(() => {
        setOpen(!open)
    }, [open])

    const handleConfirm = useCallback(() => {
        signOut()
        setShowDialog(false)
    }, [])

    const dialogProps: ModalDialogProps = useMemo((): ModalDialogProps => {
        return {
            ...ConfirmSignOutDialog,
            open: showDialog,
            handleOpen: setShowDialog,
            handleConfirm,
        }
    }, [handleConfirm, showDialog])

    return (
        <Sidebar className={open ? 'open' : 'closed'}>
            <SidebarHeader>{open && <img src={logo} alt='myAimsun logo' className='logo' />}</SidebarHeader>
            <SidebarMenu>
                <SidebarMenuItem>
                    <NavLink to='profile' className={activeClassname}>
                        <PersonRounded />
                        {open && <span>{t('sidebar.profile')}</span>}
                    </NavLink>
                </SidebarMenuItem>
                <SidebarMenuItem>
                    <NavLink to='subscriptions' className={activeClassname}>
                        <ListAltRounded />
                        {open && <span>{t('sidebar.subscriptions')}</span>}
                    </NavLink>
                </SidebarMenuItem>
                {!!projects.length && (
                    <SidebarMenuItem>
                        <NavLink to='projects' className={activeClassname}>
                            <CasesOutlined />
                            {open && <span>{t('sidebar.projects')}</span>}
                        </NavLink>
                    </SidebarMenuItem>
                )}
                <SidebarMenuItem>
                    <Link href={forumUrl} target='_blank' rel='noreferrer'>
                        <QuestionAnswerOutlined />
                        {open && <span>{t('sidebar.userForum')}</span>}
                    </Link>
                </SidebarMenuItem>
                <SidebarMenuItem>
                    <Link href={docsUrl} target='_blank' rel='noreferrer'>
                        <DescriptionOutlined />
                        {open && <span>{t('sidebar.documentation')}</span>}
                    </Link>
                </SidebarMenuItem>
                {canAccessAdminSite(profile) && (
                    <SidebarMenuItem>
                        <Link href={adminPortalUrl} target='_blank' rel='noreferrer'>
                            <AdminPanelSettingsOutlined />
                            {open && <span>{t('sidebar.adminPortal')}</span>}
                        </Link>
                    </SidebarMenuItem>
                )}
            </SidebarMenu>
            <SidebarMenuItem onClick={() => setShowDialog(true)}>
                <span>
                    <LogoutOutlined />
                    {open && <span>{t('sidebar.signOut')}</span>}
                </span>
            </SidebarMenuItem>
            <SidebarToggle onClick={handleToggle}>
                {!open ? <KeyboardArrowRightRounded /> : <KeyboardArrowLeftRounded />}
                {open && <span>{t('sidebar.showLess')}</span>}
            </SidebarToggle>
            {open && <BuildVersion />}
            <Dialog {...dialogProps} />
        </Sidebar>
    )
}

export default SideBar
