import { VpnKeyRounded, VisibilityOffRounded, VisibilityRounded, AccessTimeRounded } from '@mui/icons-material'
import { AlertColor, IconButton, InputAdornment } from '@mui/material'
import axios from 'axios'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useAppSelector } from '../../redux/hooks/hooks'
import StandardButton from '../../styles/button'
import { Header } from '../../styles/header'
import { PasswordForm, ProfileButtons } from '../../styles/profile'
import { SectionContentCentered } from '../../styles/section-content'
import StandardTextField from '../../styles/textfield'
import StandardSnackbar from '../../styles/snackbar'
import { StandardAlert } from '../../styles/alert'
import { selectAccessToken } from '../../redux/selectors/auth-selector'
import { parseJwt } from '../../utils/utils'
import { useTranslation } from 'react-i18next'

const PasswordSection = () => {
    const accessToken: string = useAppSelector(selectAccessToken)
    const [password, setPassword] = useState<string>('')
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [newPassword, setNewPassword] = useState<string>('')
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false)
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>('')
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState<boolean>(false)
    const [requireOneTimePassword, setRequireOneTimePassword] = useState<boolean>(false)
    const [oneTimePassword, setOneTimePassword] = useState<string>('')
    const [focusedInput, setFocusedInput] = useState<string>('')
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const { t } = useTranslation()

    useEffect((): void => {
        document.title = `${t('profile.password')} – ${process.env.REACT_APP_TITLE}`
    }, [t])

    const handleInputChange = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (event.target.id === 'new-password') {
            setNewPassword(event.target.value)
        } else if (event.target.id === 'confirm-new-password') {
            setConfirmNewPassword(event.target.value)
        } else if (event.target.id === 'one-time-password') {
            setOneTimePassword(event.target.value)
        } else {
            setPassword(event.target.value)
        }
    }, [])

    const handleInputFocus = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setFocusedInput(event.target.id)
    }, [])

    const handleInputBlur = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setFocusedInput('')
    }, [])

    const inputLabelProps = useCallback(
        (fieldId: string) => {
            let value = ''
            if (fieldId === 'password') {
                value = password
            } else if (fieldId === 'new-password') {
                value = newPassword
            } else if (fieldId === 'confirm-new-password') {
                value = confirmNewPassword
            }
            const shrink = fieldId === focusedInput || !!value.length
            return {
                shrink,
            }
        },
        [focusedInput, password, newPassword, confirmNewPassword]
    )

    const handleTogglePassword = useCallback(() => {
        setShowPassword(!showPassword)
    }, [showPassword])

    const handleToggleNewPassword = useCallback(() => {
        setShowNewPassword(!showNewPassword)
    }, [showNewPassword])

    const handleToggleConfirmNewPassword = useCallback(() => {
        setShowConfirmNewPassword(!showConfirmNewPassword)
    }, [showConfirmNewPassword])

    const isChanged: boolean = useMemo(() => {
        return !!(password.length && newPassword.length && confirmNewPassword.length)
    }, [password, newPassword, confirmNewPassword])

    const isSaveDisabled: boolean = useMemo(() => {
        return !isChanged || newPassword === password || newPassword !== confirmNewPassword
    }, [isChanged, password, newPassword, confirmNewPassword])

    const handleCancel = useCallback(() => {
        setPassword('')
        setNewPassword('')
        setConfirmNewPassword('')
        setRequireOneTimePassword(false)
    }, [])

    const handleSave = useCallback(() => {
        const params = new URLSearchParams()
        const payload = parseJwt(accessToken)
        params.append('username', payload.username)
        params.append('password', password)
        params.append('newPassword', newPassword)
        if (requireOneTimePassword) {
            params.append('oneTimePassword', oneTimePassword)
        }
        axios
            .post(`${process.env.REACT_APP_AUTH_URL}/api/change-password`, params)
            .then(response => {
                setShowSnackbar(true)
                handleCancel()
            })
            .catch(error => {
                if (error.response.data.name === 'TOTPRequired') {
                    setRequireOneTimePassword(true)
                } else {
                    setErrorMessage(error.response.data.message)
                    setShowSnackbar(true)
                }
            })
    }, [accessToken, password, newPassword, oneTimePassword, requireOneTimePassword, handleCancel])

    const alertText = useMemo((): string => {
        if (errorMessage) {
            return t(errorMessage)
        }
        return t('profile.alert.passwordSuccess')
    }, [errorMessage, t])

    const alertSeverity = useMemo((): AlertColor => {
        return errorMessage ? 'error' : 'success'
    }, [errorMessage])

    const handleCloseSnackbar = () => {
        setShowSnackbar(false)
    }

    return (
        <SectionContentCentered>
            <PasswordForm>
                <Header>{t('profile.password')}</Header>
                {!requireOneTimePassword && (
                    <>
                        <StandardTextField
                            id='password'
                            type={showPassword ? 'text' : 'password'}
                            label={t('profile.currentPassword')}
                            fullWidth
                            value={password}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            InputLabelProps={inputLabelProps('password')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <VpnKeyRounded />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton
                                            aria-label='toggle password visibility'
                                            onClick={handleTogglePassword}>
                                            {showPassword ? <VisibilityOffRounded /> : <VisibilityRounded />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            variant='standard'
                        />
                        <StandardTextField
                            id='new-password'
                            type={showNewPassword ? 'text' : 'password'}
                            label={t('profile.newPassword')}
                            fullWidth
                            value={newPassword}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            InputLabelProps={inputLabelProps('new-password')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <VpnKeyRounded />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton
                                            aria-label='toggle new password visibility'
                                            onClick={handleToggleNewPassword}>
                                            {showNewPassword ? <VisibilityOffRounded /> : <VisibilityRounded />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            variant='standard'
                        />
                        <StandardTextField
                            id='confirm-new-password'
                            type={showConfirmNewPassword ? 'text' : 'password'}
                            label={t('profile.confirmNewPassword')}
                            fullWidth
                            value={confirmNewPassword}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            InputLabelProps={inputLabelProps('confirm-new-password')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <VpnKeyRounded />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton
                                            aria-label='toggle confirm new password visibility'
                                            onClick={handleToggleConfirmNewPassword}>
                                            {showConfirmNewPassword ? <VisibilityOffRounded /> : <VisibilityRounded />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            variant='standard'
                        />
                    </>
                )}
                {requireOneTimePassword && (
                    <StandardTextField
                        id='one-time-password'
                        type='text'
                        label={t('profile.oneTimePassword')}
                        fullWidth
                        value={oneTimePassword}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        InputLabelProps={inputLabelProps('one-time-password')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <AccessTimeRounded />
                                </InputAdornment>
                            ),
                        }}
                        variant='standard'
                    />
                )}
                <ProfileButtons>
                    <StandardButton variant='outlined' onClick={handleCancel} disabled={!isChanged}>
                        {t('profile.cancel')}
                    </StandardButton>
                    <StandardButton onClick={handleSave} disabled={isSaveDisabled}>
                        {t('profile.save')}
                    </StandardButton>
                </ProfileButtons>
            </PasswordForm>

            <StandardSnackbar open={showSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <StandardAlert onClose={handleCloseSnackbar} severity={alertSeverity}>
                    {alertText}
                </StandardAlert>
            </StandardSnackbar>
        </SectionContentCentered>
    )
}

export default PasswordSection
