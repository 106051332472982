import { ThemeProvider } from '@mui/material'
import React, { Suspense, useEffect, useState } from 'react'
import aimsunLiveTheme from './assets/aimsun-live-theme'
import './assets/app.scss'
import Routes from './Routes'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

const App: React.FC = () => {
    const [isI18nInitialised, setIsI18nInitialised] = useState(false)

    useEffect(() => {
        async function initializeI18n() {
            await i18n
                .use(Backend)
                .use(LanguageDetector)
                .use(initReactI18next)
                .init({
                    fallbackLng: 'en',
                    debug: true,
                    interpolation: {
                        escapeValue: false,
                    },
                })
            setIsI18nInitialised(true)
        }

        initializeI18n()
    }, [])

    return (
        <ThemeProvider theme={aimsunLiveTheme}>
            <Suspense>{isI18nInitialised && <Routes />}</Suspense>
        </ThemeProvider>
    )
}

export default App
