import { AlertColor, CircularProgress } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { Buttons, ManagePanelAccount, ManagePanelContainer } from '../../styles/user-panel'
import { StandardAlert } from '../../styles/alert'
import StandardSnackbar from '../../styles/snackbar'
import {
    selectProjectsSelectedError,
    selectProjectsSelectedProject,
    selectProjectsSelectedProjectModified,
    selectProjectsSelectedStatus,
} from '../../redux/selectors/projects-selector'
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks'
import {
    archiveProject,
    fetchArchivedProjects,
    fetchProject,
    fetchProjects,
    resetSelected,
    setSelectedProjectModified,
    updateProject,
} from '../../redux/actions/projectsSlice'
import { PlainTextField } from '../../styles/textfield'
import StandardButton, { FitStandardButton } from '../../styles/button'
import { Spacer, Spacing } from '../../styles/spacing'
import { useTranslation } from 'react-i18next'
import { VerticalForm } from '../../styles/flex'
import Dialog from '../dialog'
import { ConfirmArchiveProjectDialog } from '../../utils/dialogs'
import { RpcError } from 'grpc-web'

const ManageInfoPanel = ({ handleChanged, handleClosing }: ManagePanelProps) => {
    const dispatch = useAppDispatch()
    const project: ProjectInfo = useAppSelector(selectProjectsSelectedProject)
    const projectModified: ProjectInfo = useAppSelector(selectProjectsSelectedProjectModified)
    const status: string = useAppSelector(selectProjectsSelectedStatus)
    const error: RpcError = useAppSelector(selectProjectsSelectedError)
    const [showDialog, setShowDialog] = useState<boolean>(false)
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
    const { t } = useTranslation()

    useEffect(() => {
        if (status === 'not_loaded') {
            dispatch(fetchProject(project.project.projectid))
        } else if (status === 'success' || status === 'failed') {
            setShowSnackbar(true)
        }
    }, [dispatch, project, status])

    const loaded: boolean = useMemo((): boolean => status === 'loaded' || status === 'failed', [status])

    const handleTextfield = useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            dispatch(
                setSelectedProjectModified({
                    ...projectModified,
                    project: {
                        ...projectModified.project,
                        [event.target.id]: event.target.value,
                    },
                })
            )
        },
        [dispatch, projectModified]
    )

    const isChanged: boolean = useMemo(
        () => JSON.stringify(project) !== JSON.stringify(projectModified),
        [project, projectModified]
    )

    const isUpdating: boolean = useMemo(() => status === 'loading', [status])

    const handleSave = useCallback(() => {
        handleChanged(true)
        if (isChanged) {
            dispatch(updateProject(projectModified.project))
        }
    }, [dispatch, projectModified, isChanged, handleChanged])

    const handleArchiveProject = useCallback(() => {
        dispatch(archiveProject(project.project.projectid)).then(data => {
            dispatch(fetchProjects())
            dispatch(fetchArchivedProjects())
            if (data.payload) {
                dispatch(resetSelected())
            }
        })
    }, [dispatch, project])

    const alertText = useMemo((): string => {
        if (error) {
            return error.message
        }
        return t('projects.updatedInfo')
    }, [error, t])

    const alertSeverity: AlertColor = useMemo((): AlertColor => {
        return error ? 'error' : 'success'
    }, [error])

    const handleCloseSnackbar = useCallback(() => {
        setShowSnackbar(false)
    }, [setShowSnackbar])

    const dialogProps: ModalDialogProps = useMemo((): ModalDialogProps => {
        return {
            ...ConfirmArchiveProjectDialog,
            open: showDialog,
            handleOpen: setShowDialog,
            handleConfirm: handleArchiveProject,
        }
    }, [handleArchiveProject, showDialog])

    return (
        <ManagePanelContainer>
            {!loaded && <CircularProgress color='inherit' />}
            {loaded && (
                <VerticalForm onSubmit={handleSave}>
                    <ManagePanelAccount>
                        <PlainTextField
                            id='name'
                            label={t('projects.projectid')}
                            value={projectModified.project.projectid}
                            variant='standard'
                            disabled
                        />
                        <PlainTextField
                            id='name'
                            label={t('projects.name')}
                            value={projectModified.project.name}
                            variant='standard'
                            disabled={isUpdating}
                            onChange={handleTextfield}
                        />
                        <PlainTextField
                            id='url'
                            label={t('projects.url')}
                            value={projectModified.project.url}
                            variant='standard'
                            disabled={isUpdating}
                            onChange={handleTextfield}
                        />
                        <PlainTextField
                            id='description'
                            label={t('projects.description')}
                            value={projectModified.project.description}
                            variant='standard'
                            multiline
                            minRows={2}
                            maxRows={Infinity}
                            disabled={isUpdating}
                            onChange={handleTextfield}
                        />
                    </ManagePanelAccount>
                    <Spacing />
                    <Buttons>
                        <StandardButton variant='outlined' onClick={handleClosing}>
                            {t('projects.cancel')}
                        </StandardButton>
                        <StandardButton type='submit' disabled={!isChanged || isUpdating}>
                            {t('projects.save')}
                        </StandardButton>
                    </Buttons>
                    <Spacing />
                    <Spacer />
                    <Buttons>
                        <FitStandardButton variant='outlined' color='error' onClick={() => setShowDialog(true)}>
                            {t('projects.archive')}
                        </FitStandardButton>
                    </Buttons>
                </VerticalForm>
            )}
            <StandardSnackbar open={showSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <StandardAlert onClose={handleCloseSnackbar} severity={alertSeverity}>
                    {alertText}
                </StandardAlert>
            </StandardSnackbar>
            <Dialog {...dialogProps} />
        </ManagePanelContainer>
    )
}

export default ManageInfoPanel
