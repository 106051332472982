import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import Profile from './containers/profile'
import Root from './containers/root'
import PasswordSection from './containers/sections/password-section'
import ProfileSection from './containers/sections/profile-section'
import MFASection from './containers/sections/mfa-section'
import DownloadsSection from './containers/sections/downloads-section'
import RootError from './containers/root-error'
import Error from './components/error'
import Subscriptions from './containers/subscriptions'
import MySubscriptionsSection from './containers/sections/my-subscriptions-section'
import Projects from './containers/projects'
import MyProjectsSection from './containers/sections/my-projects-section'

const Routes = () => {
    const router = createBrowserRouter([
        {
            path: '/',
            element: <Root />,
            errorElement: <RootError />,
            children: [
                {
                    index: true,
                    element: <Navigate to='profile' />,
                },
                {
                    path: 'profile',
                    element: <Profile />,
                    errorElement: <Error />,
                    children: [
                        {
                            index: true,
                            element: <Navigate to='about' />,
                        },
                        {
                            path: 'about',
                            element: <ProfileSection />,
                        },
                        {
                            path: 'password',
                            element: <PasswordSection />,
                        },
                        {
                            path: 'mfa',
                            element: <MFASection />,
                        },
                    ],
                },
                {
                    path: 'subscriptions',
                    element: <Subscriptions />,
                    errorElement: <Error />,
                    children: [
                        {
                            index: true,
                            element: <Navigate to='my-subscriptions' replace={true} />,
                        },
                        {
                            path: 'my-subscriptions',
                            element: <MySubscriptionsSection />,
                        },
                        {
                            path: 'downloads',
                            element: <DownloadsSection />,
                        },
                    ],
                },
                {
                    path: 'projects',
                    element: <Projects />,
                    errorElement: <Error />,
                    children: [
                        {
                            index: true,
                            element: <Navigate to='my-projects' replace={true} />,
                        },
                        {
                            path: 'my-projects',
                            element: <MyProjectsSection />,
                        },
                    ],
                },
            ],
        },
    ])

    return <RouterProvider router={router} />
}

export default Routes
