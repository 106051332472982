import { VpnKeyRounded, VisibilityOffRounded, VisibilityRounded, AccessTimeRounded } from '@mui/icons-material'
import { AlertColor, IconButton, InputAdornment } from '@mui/material'
import axios from 'axios'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useAppSelector } from '../../redux/hooks/hooks'
import StandardButton from '../../styles/button'
import { Header } from '../../styles/header'
import { PasswordForm, ProfileButtons } from '../../styles/profile'
import { SectionContentCentered } from '../../styles/section-content'
import StandardTextField from '../../styles/textfield'
import StandardSnackbar from '../../styles/snackbar'
import { StandardAlert } from '../../styles/alert'
import QRCode from 'react-qr-code'
import { browserDetect, parseJwt } from '../../utils/utils'
import { selectAccessToken } from '../../redux/selectors/auth-selector'
import { useTranslation } from 'react-i18next'

const MFASection = () => {
    const accessToken: string = useAppSelector(selectAccessToken)
    const [status, setStatus] = useState<MFAFlow>('input_password')
    const [password, setPassword] = useState<string>('')
    const [secretCode, setSecretCode] = useState<string>('')
    const [oneTimePassword, setOneTimePassword] = useState<string>('')
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [focusedInput, setFocusedInput] = useState<string>('')
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const { t } = useTranslation()

    useEffect((): void => {
        document.title = `${t('profile.mfa')} – ${process.env.REACT_APP_TITLE}`
    }, [t])

    const handleInputChange = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (event.target.id === 'one-time-password') {
            if (event.target.value.length <= 6) {
                setOneTimePassword(event.target.value)
            }
        } else {
            setPassword(event.target.value)
        }
    }, [])

    const handleInputFocus = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setFocusedInput(event.target.id)
    }, [])

    const handleInputBlur = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setFocusedInput('')
    }, [])

    const inputLabelProps = useCallback(
        (fieldId: string) => {
            let value = ''
            if (fieldId === 'password') {
                value = password
            } else if (fieldId === 'one-time-password') {
                value = oneTimePassword
            }
            const shrink = fieldId === focusedInput || !!value.length
            return {
                shrink,
            }
        },
        [focusedInput, password, oneTimePassword]
    )

    const handleTogglePassword = useCallback(() => {
        setShowPassword(!showPassword)
    }, [showPassword])

    const isChanged: boolean = useMemo(() => {
        return !!password.length
    }, [password])

    const isSaveDisabled: boolean = useMemo(() => {
        return !isChanged
    }, [isChanged])

    const handleCancel = useCallback(() => {
        setPassword('')
        setStatus('input_password')
    }, [setPassword, setStatus])

    const handleAssociateSoftwareToken = useCallback(() => {
        const params = new URLSearchParams()
        const payload = parseJwt(accessToken)
        params.append('username', payload.username)
        params.append('password', password)
        axios
            .post(`${process.env.REACT_APP_AUTH_URL}/api/associate-software-token`, params)
            .then(response => {
                setStatus('verify_secret_code')
                setSecretCode(response.data.secretCode)
            })
            .catch(error => {
                if (error.response.data.name === 'TOTPRequired') {
                    setStatus('enabled')
                } else {
                    setErrorMessage(error.response.data.message)
                    setShowSnackbar(true)
                    handleCancel()
                }
            })
    }, [accessToken, password, setStatus, setSecretCode, handleCancel])

    const handleSetMFAPreference = useCallback(() => {
        const params = new URLSearchParams()
        const payload = parseJwt(accessToken)
        params.append('username', payload.username)
        params.append('password', password)
        params.append('oneTimePassword', oneTimePassword)
        params.append('method', status === 'enabled' ? 'null' : 'totp')
        axios
            .post(`${process.env.REACT_APP_AUTH_URL}/api/set-mfa-preference`, params)
            .then(() => {
                setShowSnackbar(true)
                setStatus('input_password')
                setPassword('')
                setOneTimePassword('')
            })
            .catch(error => {
                setErrorMessage(error.response.data.message)
                setShowSnackbar(true)
            })
    }, [accessToken, password, status, oneTimePassword])

    const handleVerifySoftwareToken = useCallback(() => {
        const params = new URLSearchParams()
        const payload = parseJwt(accessToken)
        params.append('username', payload.username)
        params.append('password', password)
        params.append('oneTimePassword', oneTimePassword)
        params.append('friendlyDeviceName', browserDetect())
        axios
            .post(`${process.env.REACT_APP_AUTH_URL}/api/verify-software-token`, params)
            .then(() => {
                handleSetMFAPreference()
            })
            .catch(error => {
                setErrorMessage(error.response.data.message)
                setShowSnackbar(true)
            })
    }, [accessToken, password, oneTimePassword, handleSetMFAPreference])

    const qrUrl = useMemo((): string => {
        const payload = parseJwt(accessToken)
        return `otpauth://totp/myAimsun:${payload.username}?issuer=Aimsun&secret=${secretCode}`
    }, [accessToken, secretCode])

    const alertText = useMemo((): string => {
        if (errorMessage) {
            return t(errorMessage)
        }
        return t('profile.alerts.mfaSuccess')
    }, [errorMessage, t])

    const alertSeverity = useMemo((): AlertColor => {
        return errorMessage ? 'error' : 'success'
    }, [errorMessage])

    const handleCloseSnackbar = () => {
        setShowSnackbar(false)
    }

    return (
        <SectionContentCentered>
            <PasswordForm>
                <Header>{t('profile.mfa')}</Header>
                {status === 'input_password' && (
                    <StandardTextField
                        id='password'
                        type={showPassword ? 'text' : 'password'}
                        label={t('profile.currentPassword')}
                        fullWidth
                        value={password}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        InputLabelProps={inputLabelProps('password')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <VpnKeyRounded />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton aria-label='toggle password visibility' onClick={handleTogglePassword}>
                                        {showPassword ? <VisibilityOffRounded /> : <VisibilityRounded />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        variant='standard'
                    />
                )}
                {status === 'verify_secret_code' && (
                    <>
                        <QRCode value={qrUrl} />
                        <span>{t('profile.totpInstructions')}</span>
                    </>
                )}

                {(status === 'verify_secret_code' || status === 'enabled') && (
                    <StandardTextField
                        id='one-time-password'
                        type='text'
                        label={t('profile.oneTimePassword')}
                        fullWidth
                        value={oneTimePassword}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        InputLabelProps={inputLabelProps('one-time-password')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <AccessTimeRounded />
                                </InputAdornment>
                            ),
                        }}
                        variant='standard'
                    />
                )}
                <ProfileButtons>
                    <StandardButton variant='outlined' onClick={handleCancel}>
                        {t('profile.cancel')}
                    </StandardButton>
                    {status === 'input_password' && (
                        <StandardButton onClick={handleAssociateSoftwareToken} disabled={isSaveDisabled}>
                            {t('profile.continue')}
                        </StandardButton>
                    )}
                    {status === 'verify_secret_code' && (
                        <StandardButton onClick={handleVerifySoftwareToken}>{t('profile.verify')}</StandardButton>
                    )}
                    {status === 'enabled' && (
                        <StandardButton onClick={handleSetMFAPreference}>{t('profile.disable')}</StandardButton>
                    )}
                </ProfileButtons>
            </PasswordForm>

            <StandardSnackbar open={showSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <StandardAlert onClose={handleCloseSnackbar} severity={alertSeverity}>
                    {alertText}
                </StandardAlert>
            </StandardSnackbar>
        </SectionContentCentered>
    )
}

export default MFASection
