import { useRouteError } from 'react-router-dom'
import SideBar from '../components/sidebar'
import { Header, Subheader } from '../styles/header'
import { MainContainer, PageCentered } from '../styles/page'
import { FlexRow } from '../styles/flex'
import { useTranslation } from 'react-i18next'

const RootError: React.FC = () => {
    const error: Error = useRouteError() as Error
    const { t } = useTranslation()
    return (
        <FlexRow>
            <SideBar />
            <MainContainer>
                <PageCentered>
                    <Header>{t('error.genericTitle')}</Header>
                    <Subheader>{t(error.message) || t('error.genericMessage')}</Subheader>
                </PageCentered>
            </MainContainer>
        </FlexRow>
    )
}

export default RootError
