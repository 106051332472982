import { KeyboardArrowRightRounded } from '@mui/icons-material'
import { NavLink } from 'react-router-dom'
import {
    SectionSidebar,
    SectionSidebarContainer,
    SectionSidebarHeader,
    SectionSidebarHeaderSkeleton,
    SectionSidebarMenu,
    SectionSidebarMenuItem,
    SectionSidebarSubheader,
    SectionSidebarSubheaderSkeleton,
} from '../styles/section-sidebar'
import { getName } from '../utils/utils'
import { useAppSelector } from '../redux/hooks/hooks'
import { selectProfile, selectProfileStatus } from '../redux/selectors/profile-selector'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const SectionSidebarProfile = () => {
    const profile: Profile = useAppSelector(selectProfile)
    const profileStatus: LoadStatus = useAppSelector(selectProfileStatus)
    const { t } = useTranslation()

    const loaded: boolean = useMemo(
        (): boolean => profileStatus === 'loaded' && Object.keys(profile).length > 0,
        [profile, profileStatus]
    )

    const showPassword = useMemo((): boolean => {
        if (loaded) {
            return !profile.cognitoid.includes('@')
        }
        return false
    }, [profile, loaded])

    return (
        <SectionSidebarContainer>
            <SectionSidebar>
                {!loaded && (
                    <>
                        <SectionSidebarHeaderSkeleton variant='text' />
                        <SectionSidebarSubheaderSkeleton variant='text' />
                    </>
                )}
                {loaded && (
                    <>
                        <SectionSidebarHeader>{getName(profile)}</SectionSidebarHeader>
                        <SectionSidebarSubheader>{profile.email}</SectionSidebarSubheader>
                    </>
                )}
                <SectionSidebarMenu>
                    <SectionSidebarMenuItem>
                        <NavLink to='about'>
                            <span>{t('profile.profile')}</span>
                            <KeyboardArrowRightRounded />
                        </NavLink>
                    </SectionSidebarMenuItem>
                    {showPassword && (
                        <SectionSidebarMenuItem>
                            <NavLink to='password'>
                                <span>{t('profile.password')}</span>
                                <KeyboardArrowRightRounded />
                            </NavLink>
                        </SectionSidebarMenuItem>
                    )}
                    {showPassword && (
                        <SectionSidebarMenuItem>
                            <NavLink to='mfa'>
                                <span>{t('profile.mfa')}</span>
                                <KeyboardArrowRightRounded />
                            </NavLink>
                        </SectionSidebarMenuItem>
                    )}
                </SectionSidebarMenu>
            </SectionSidebar>
        </SectionSidebarContainer>
    )
}

export default SectionSidebarProfile
