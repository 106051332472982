import { Alert, Checkbox, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import StandardButton from '../styles/button'
import StandardDialog, { DialogContainer } from '../styles/dialog'
import { useTranslation } from 'react-i18next'
import { PlainTextField } from '../styles/textfield'
import { useAppSelector } from '../redux/hooks/hooks'
import {
    selectCreateProjectError,
    selectCreateProjectStatus,
    selectProjectsSelectedProject,
} from '../redux/selectors/projects-selector'

const AddProjectUserDialog = ({ open, handleOpen, handleAddProjectUser }: any) => {
    const project = useAppSelector(selectProjectsSelectedProject)
    const status = useAppSelector(selectCreateProjectStatus)
    const error = useAppSelector(selectCreateProjectError)
    const [showAlert, setShowAlert] = useState<boolean>(false)

    const [addProjectUserData, setAddProjectUserData] = useState<AddProjectUserData>({
        projectId: project.project.projectid,
        email: '',
        sendEmail: false,
    })
    const { t } = useTranslation()

    const handleClose = useCallback(() => {
        if (handleOpen) {
            handleOpen(false)
        }
    }, [handleOpen])

    useEffect(() => {
        if (!showAlert && status === 'failed') {
            setShowAlert(true)
        } else if (status === 'success') {
            handleClose()
        }
    }, [status, showAlert, handleClose])

    const alertText = useMemo((): string => {
        if (error) {
            return error.message
        }
        return t('projects.userAdded')
    }, [error, t])

    return (
        <StandardDialog
            open={open}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'>
            <DialogContainer>
                <DialogTitle id='alert-dialog-title'>{t('projects.addProjectUser')}</DialogTitle>
                <DialogContent>
                    <PlainTextField
                        id='email'
                        type='text'
                        label={t('projects.email')}
                        fullWidth
                        value={addProjectUserData.email || ''}
                        onChange={event => setAddProjectUserData({ ...addProjectUserData, email: event.target.value! })}
                        variant='standard'
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={(_, checked) =>
                                    setAddProjectUserData({ ...addProjectUserData, sendEmail: checked })
                                }
                            />
                        }
                        label={t('projects.sendEmail')}
                    />

                    {showAlert && <Alert severity={'error'}>{alertText}</Alert>}
                </DialogContent>
                <DialogActions>
                    <StandardButton variant='outlined' onClick={handleClose}>
                        {t('dialog.cancel')}
                    </StandardButton>

                    <StandardButton
                        onClick={() => handleAddProjectUser(addProjectUserData)}
                        disabled={!addProjectUserData.email.length}>
                        {t('projects.save')}
                    </StandardButton>
                </DialogActions>
            </DialogContainer>
        </StandardDialog>
    )
}

export default AddProjectUserDialog
