import { styled } from '@mui/material'
import { FlexColumnCenter, FlexRow } from './flex'

export const MainContainer = styled(FlexRow)({
    flexGrow: 1,
})

export const Page = styled(FlexRow)({})

export const PageCentered = styled(FlexColumnCenter)({
    padding: '238px 48px',
    maxHeight: 'calc(100vh - (238px * 2))',
    overflowY: 'auto',
    flexGrow: 1,
})
