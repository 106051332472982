import { InputLabel, Skeleton, styled, TextField } from '@mui/material'

const StandardTextField = styled(TextField)(({ theme }) => ({
    position: 'relative',
    color: theme.palette.custom.grey.main,
    display: 'flex',
    alignItems: 'center',
    maxWidth: 350,

    '.MuiInput-underline:before': {
        borderWidth: 1,
        borderColor: theme.palette.custom.lightGrey.main,
    },

    '.MuiInputBase-root:hover:not(.Mui-disabled,.Mui-error):before': {
        borderWidth: 1,
        borderColor: theme.palette.custom.mediumGrey.main,
    },

    label: {
        left: 49,
        letterSpacing: 0.32,
        color: theme.palette.custom.grey.main,
        fontWeight: 400,
        transform: 'translate(0, 23px) scale(1)',

        '&.MuiInputLabel-shrink': {
            transform: 'translate(0, 2px) scale(0.75)',
        },

        '&.Mui-focused': {
            color: theme.palette.custom.grey.main,
        },
    },

    input: {
        flexGrow: 1,
        height: 22,
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 49,
        paddingRight: 49,
        fontFamily: 'RegularAimsunMedium',
        letterSpacing: 0.32,
        color: theme.palette.primary.main,

        '::placeholder': {
            color: theme.palette.custom.grey.main,
        },
    },

    '.Mui-disabled:before': {
        borderBottomStyle: 'solid !important',
    },

    '& .MuiInputAdornment-positionStart': {
        position: 'absolute',
        color: theme.palette.custom.lightGrey.main,
        left: 12,
    },

    '& .MuiInputAdornment-positionEnd': {
        position: 'absolute',
        color: theme.palette.custom.lightGrey.main,
        right: 2,

        button: {
            color: theme.palette.custom.lightGrey.main,
        },
    },
}))

export const PlainTextField = styled(TextField)(({ theme }) => ({
    fontSize: 16,
    color: theme.palette.primary.main,
    border: 'none',

    label: {
        left: 0,
        letterSpacing: 0.32,
        color: theme.palette.custom.darkGrey.main,
        fontWeight: 400,
        transform: 'translate(0, 23px) scale(1)',

        '&.MuiInputLabel-shrink': {
            transform: 'translate(0, 2px) scale(0.75)',
        },

        '&.Mui-focused': {
            color: theme.palette.custom.darkGrey.main,
        },
    },

    '.MuiInput-root:hover': {
        border: 'none',
    },

    '.MuiInput-underline:before': {
        borderWidth: 1,
        borderColor: 'transparent',
    },

    '.MuiInputBase-root:hover:not(.Mui-disabled,.Mui-error):before': {
        borderWidth: 1,
        borderColor: theme.palette.custom.grey.main,
    },

    '.Mui-disabled': {
        color: `${theme.palette.custom.darkGrey.main} !important`,

        '.MuiInput-input': {
            color: theme.palette.custom.darkGrey.main,
            WebkitTextFillColor: theme.palette.custom.darkGrey.main,
        },

        '&:before': {
            borderBottomStyle: 'hidden !important',
        },
    },
}))

export const TextFieldSkeleton = styled(Skeleton)({
    marginBottom: 14,
    fontSize: 16,
    height: 32,
})

export const TextFieldLabel = styled(InputLabel)(({ theme }) => ({
    fontSize: 12,
    color: theme.palette.custom.darkGrey.main,
    transform: 'none',
}))

export default StandardTextField
