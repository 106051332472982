import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { callSeatAssignments } from '../../grpc/api'

const initialState: SubscriptionsState = {
    seatAssignments: [],
    status: 'not_loaded',
    error: null,
}

export const subscriptionsSlice = createSlice({
    name: 'subscriptions',
    initialState,
    reducers: {
        setSeatAssignments: (state, action: PayloadAction<SeatAssignment[]>) => {
            state.seatAssignments = action.payload
            state.status = 'loaded'
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchSeatAssignments.pending, state => {
                state.status = 'loading'
            })
            .addCase(fetchSeatAssignments.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.seatAssignments = action.payload
                state.error = null
            })
            .addCase(fetchSeatAssignments.rejected, (state, action) => {
                state.status = 'failed'
                state.error = { ...action.error, code: Number.parseInt(action.error.code!) }
            })
    },
})

export const fetchSeatAssignments = createAsyncThunk(
    'subscriptions/fetchSeatAssignments',
    async (): Promise<SeatAssignment[]> => {
        return (await callSeatAssignments()).seatsList
    }
)

export const { setSeatAssignments } = subscriptionsSlice.actions

export default subscriptionsSlice.reducer
