import { Outlet } from 'react-router-dom'
import SectionSidebarSubscriptions from '../components/section-sidebar-subscriptions'

const Subscriptions: React.FC = () => {
    return (
        <>
            <SectionSidebarSubscriptions />
            <Outlet />
        </>
    )
}

export default Subscriptions
