import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState: DownloadsState = {
    products: [],
    status: 'not_loaded',
    error: null,
}

export const downloadsSlice = createSlice({
    name: 'downloads',
    initialState,
    reducers: {
        setProducts: (state: DownloadsState, action: PayloadAction<ProductState[]>): void => {
            state.products = action.payload
            state.status = 'loaded'
        },
        setProductsStatus: (state: DownloadsState, action: PayloadAction<LoadStatus>): void => {
            state.status = action.payload
        },
    },
    extraReducers(builder): void {
        builder
            .addCase(fetchDownloads.pending, (state: DownloadsState): void => {
                state.status = 'loading'
            })
            .addCase(
                fetchDownloads.fulfilled,
                (state: DownloadsState, action: PayloadAction<VersionsResponse, string>): void => {
                    state.products = action.payload.products
                    state.error = null
                    state.status = 'loaded'
                }
            )
            .addCase(fetchDownloads.rejected, (state: DownloadsState, action): void => {
                state.error = { ...action.error, code: Number.parseInt(action.error.code!) }
                state.status = 'failed'
            })
    },
})

export const fetchDownloads = createAsyncThunk('downloads/fetchDownloads', async (): Promise<VersionsResponse> => {
    const timestamp: number = new Date().getTime()
    return axios
        .get(`https://s3.amazonaws.com/releases.aimsun.com/versions.json?${timestamp}`)
        .then(response => response.data)
})

export const { setProducts, setProductsStatus } = downloadsSlice.actions

export default downloadsSlice.reducer
