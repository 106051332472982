import { AlertColor, Checkbox, CircularProgress, FormControl, FormControlLabel, MenuItem } from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { Buttons, ManagePanelContainer } from '../../styles/user-panel'
import { StandardAlert } from '../../styles/alert'
import StandardSnackbar from '../../styles/snackbar'
import {
    selectProjectsSelectedError,
    selectProjectsSelectedProject,
    selectProjectsSelectedStatus,
} from '../../redux/selectors/projects-selector'
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks'
import {
    StandardTable,
    StandardTableHead,
    StandardTableRow,
    StandardTableCell,
    StandardTableBody,
} from '../../styles/table'

import {
    addProjectUser,
    fetchProject,
    fetchProjects,
    removeProjectUser,
    resetSelected,
    updateProjectUserRole,
} from '../../redux/actions/projectsSlice'
import { useTranslation } from 'react-i18next'
import { projectRoleToString } from '../../utils/utils'
import StandardButton from '../../styles/button'
import { Spacer, Spacing } from '../../styles/spacing'
import { PlainSelect } from '../../styles/select'
import AddProjectUserDialog from '../add-project-user-dialog'
import { selectProfile } from '../../redux/selectors/profile-selector'
import { RpcError } from 'grpc-web'

const ManageUsersPanel = ({ handleChanged, handleClosing }: ManagePanelProps) => {
    const dispatch = useAppDispatch()
    const project: ProjectInfo = useAppSelector(selectProjectsSelectedProject)
    const status: string = useAppSelector(selectProjectsSelectedStatus)
    const error: RpcError = useAppSelector(selectProjectsSelectedError)
    const profile: Profile = useAppSelector(selectProfile)
    const [showDialog, setShowDialog] = useState<boolean>(false)
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
    const [selected, setSelected] = useState<string[]>([])
    const { t } = useTranslation()

    useEffect(() => {
        if (status === 'not_loaded') {
            dispatch(fetchProject(project.project.projectid))
        } else if (status === 'success' || status === 'failed') {
            setShowSnackbar(true)
        }
    }, [dispatch, project, status])

    const loaded: boolean = useMemo((): boolean => status === 'loaded' || status === 'failed', [status])

    const hasOnlyOneAdmin: boolean = useMemo(
        (): boolean => project.usersList.filter(user => user.role === 2).length <= 1,
        [project]
    )

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (checked) {
            setSelected([...selected, event.target.id])
        } else {
            setSelected(selected.filter(item => item !== event.target.id))
        }
    }

    const handleRoleChange = (user: ProjectUser, role: ProjectRole) => {
        dispatch(
            updateProjectUserRole({
                projectId: project.project.projectid,
                userId: user.userid,
                role,
            })
        ).then(() => {
            if (user.userid === profile.userid) {
                dispatch(resetSelected())
                dispatch(fetchProjects())
            } else {
                dispatch(fetchProject(project.project.projectid))
            }
        })
    }

    const handleAddUser = () => {
        setShowDialog(true)
    }

    const handleAddProjectUser = (data: AddProjectUserData) => {
        dispatch(addProjectUser(data)).then(() => {
            dispatch(fetchProject(project.project.projectid))
            setShowDialog(false)
        })
    }

    const handleRemoveUser = () => {
        Promise.all(
            selected.map(userId =>
                dispatch(
                    removeProjectUser({
                        projectId: project.project.projectid,
                        userId,
                    })
                )
            )
        ).then(() => {
            setSelected([])
            dispatch(fetchProject(project.project.projectid))
        })
    }

    const alertText = useMemo((): string => {
        if (error) {
            return error.message
        }
        return 'Project updated successfully'
    }, [error])

    const alertSeverity: AlertColor = useMemo((): AlertColor => {
        return error ? 'error' : 'success'
    }, [error])

    const handleCloseSnackbar = useCallback(() => {
        setShowSnackbar(false)
    }, [setShowSnackbar])

    return (
        <ManagePanelContainer>
            {!loaded && <CircularProgress color='inherit' />}
            {loaded && (
                <>
                    <StandardTable>
                        <StandardTableHead>
                            <StandardTableRow>
                                <StandardTableCell className='compact'>{t('projects.user')}</StandardTableCell>
                                <StandardTableCell className='compact'>{t('projects.email')}</StandardTableCell>
                                <StandardTableCell className='compact'>{t('projects.role')}</StandardTableCell>
                            </StandardTableRow>
                        </StandardTableHead>

                        <StandardTableBody>
                            {!project.usersList && (
                                <StandardTableRow>
                                    <StandardTableCell colSpan={4}>No subscriptions to display</StandardTableCell>
                                </StandardTableRow>
                            )}
                            {project.usersList.map((user: ProjectUser) => (
                                <StandardTableRow key={user.userid}>
                                    <StandardTableCell className='compact'>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    id={user.userid}
                                                    onChange={handleCheckboxChange}
                                                    disabled={hasOnlyOneAdmin && user.role === 2}
                                                />
                                            }
                                            label={`${user.firstname} ${user.lastname}`}
                                        />
                                    </StandardTableCell>
                                    <StandardTableCell className='compact'>{user.email}</StandardTableCell>
                                    <StandardTableCell className='compact'>
                                        <FormControl variant='standard'>
                                            <PlainSelect
                                                id='role'
                                                labelId='role-label'
                                                label={t('projects.role')}
                                                value={user.role}
                                                disabled={hasOnlyOneAdmin && user.role === 2}
                                                onChange={event =>
                                                    handleRoleChange(user, event.target.value as ProjectRole)
                                                }>
                                                {[0, 1, 2].map(role => (
                                                    <MenuItem
                                                        value={role}
                                                        id={`${user.userid}-${projectRoleToString(role)}`}
                                                        key={`${user.userid}-${projectRoleToString(role)}`}>
                                                        {t(`projects.${projectRoleToString(role)}`)}
                                                    </MenuItem>
                                                ))}
                                            </PlainSelect>
                                        </FormControl>
                                    </StandardTableCell>
                                </StandardTableRow>
                            ))}
                        </StandardTableBody>
                    </StandardTable>

                    <Spacing />
                    <Buttons>
                        <StandardButton
                            variant='outlined'
                            color={'error'}
                            disabled={!selected.length}
                            onClick={handleRemoveUser}>
                            {t('projects.removeUser')}
                        </StandardButton>
                        <Spacer />

                        <StandardButton onClick={handleAddUser}>{t('projects.addUser')}</StandardButton>
                    </Buttons>
                </>
            )}
            <StandardSnackbar open={showSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <StandardAlert onClose={handleCloseSnackbar} severity={alertSeverity}>
                    {alertText}
                </StandardAlert>
            </StandardSnackbar>
            {showDialog && (
                <AddProjectUserDialog
                    open={showDialog}
                    handleOpen={setShowDialog}
                    handleAddProjectUser={handleAddProjectUser}
                />
            )}
        </ManagePanelContainer>
    )
}

export default ManageUsersPanel
