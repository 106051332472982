import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { callProfile, callUpdateProfile } from '../../grpc/api'

const initialState: ProfileState = {
    profile: {
        cognitoid: 'cognitoid',
        email: 'user@server.net',
        firstname: 'Name',
        lastname: 'LastName',
        salutation: 'Mr.',
        userid: 'userid',
        usertype: 1,
        address: {
            city: 'City',
            country: 'Country',
            countrycode: 'Country',
            postalcode: '00001',
            province: 'Province',
            street: 'Fake Street',
        },
    },
    status: 'not_loaded',
    error: null,
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setProfile: (state: ProfileState, action: PayloadAction<Profile>): void => {
            state.profile = action.payload
            state.status = 'loaded'
        },
        setProfileStatus: (state: ProfileState, action: PayloadAction<LoadStatus>): void => {
            state.status = action.payload
        },
    },
    extraReducers(builder): void {
        builder
            .addCase(fetchProfile.pending, (state: ProfileState): void => {
                state.status = 'loading'
            })
            .addCase(fetchProfile.fulfilled, (state: ProfileState, action: PayloadAction<Profile, string>): void => {
                state.profile = action.payload
                state.error = null
                state.status = 'loaded'
            })
            .addCase(fetchProfile.rejected, (state: ProfileState, action): void => {
                state.error = { ...action.error, code: Number.parseInt(action.error.code!) }
                state.status = 'failed'
            })
            .addCase(updateProfile.pending, (state: ProfileState): void => {
                state.status = 'loading'
            })
            .addCase(updateProfile.fulfilled, (state: ProfileState, action: PayloadAction<Profile, string>): void => {
                state.profile = action.payload
                state.error = null
                state.status = 'success'
            })
            .addCase(updateProfile.rejected, (state: ProfileState, action): void => {
                state.error = { ...action.error, code: Number.parseInt(action.error.code!) }
                state.status = 'failed'
            })
    },
})

export const fetchProfile = createAsyncThunk('profile/fetchProfile', async (): Promise<Profile> => {
    return await callProfile()
})

export const updateProfile = createAsyncThunk(
    'profile/updateProfile',
    async (updatedProfile: Profile): Promise<Profile> => {
        await callUpdateProfile(updatedProfile)
        return updatedProfile
    }
)

export const { setProfile, setProfileStatus } = profileSlice.actions

export default profileSlice.reducer
