import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { parseJwt } from '../../utils/utils'

const loadPersistedState = (): AuthState => {
    const storedUuid = localStorage.getItem('uuid')
    const storedAccessToken = localStorage.getItem('access_token')
    const storedRefreshToken = localStorage.getItem('refresh_token')
    const storedIdToken = localStorage.getItem('id_token')
    let uuid, accessToken, refreshToken, idToken
    let status: AuthStatus = 'unauthenticated'
    if (storedUuid) {
        uuid = JSON.parse(storedUuid)
    } else {
        uuid = crypto.randomUUID()
    }
    if (storedAccessToken && storedAccessToken !== 'undefined') {
        accessToken = JSON.parse(storedAccessToken)
        status = 'authenticated'
    }
    if (storedRefreshToken && storedRefreshToken !== 'undefined') {
        refreshToken = JSON.parse(storedRefreshToken)
    }
    if (storedIdToken && storedIdToken !== 'undefined') {
        idToken = JSON.parse(storedIdToken)
    }
    return { uuid, accessToken, refreshToken, idToken, status }
}

const initialState: AuthState = loadPersistedState()

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUuid: (state, action: PayloadAction<string>) => {
            state.uuid = action.payload
        },
        setAuthTokens: (state, action: PayloadAction<AuthPayload>) => {
            state.accessToken = action.payload.accessToken
            state.refreshToken = action.payload.refreshToken
            state.idToken = action.payload.idToken
        },
        setAuthStatus: (state, action: PayloadAction<AuthStatus>) => {
            state.status = action.payload
        },
    },

    extraReducers(builder): void {
        builder
            .addCase(fetchRefreshToken.pending, (state: AuthState): void => {
                state.status = 'refreshing'
            })
            .addCase(
                fetchRefreshToken.fulfilled,
                (state: AuthState, action: PayloadAction<RefreshTokenResponse, string>): void => {
                    state.accessToken = action.payload.access_token
                    state.refreshToken = action.payload.refresh_token
                    state.idToken = action.payload.id_token
                    state.status = 'authenticated'
                }
            )
            .addCase(fetchRefreshToken.rejected, (state: AuthState): void => {
                state.status = 'unauthenticated'
            })
    },
})

export const fetchRefreshToken = createAsyncThunk(
    'auth/refreshToken',
    async (_, { getState }): Promise<RefreshTokenResponse> => {
        const state = getState() as StoreState
        const { accessToken, refreshToken } = state.auth
        const jwtContent: JwtContent = parseJwt(accessToken)
        const params = new URLSearchParams()
        params.append('redirect', window.location.href.replace(window.location.pathname, '/'))
        params.append('refresh_token', refreshToken)
        params.append('scope', jwtContent.scope)
        const response = await axios.post(`${process.env.REACT_APP_AUTH_URL}/api/token`, params)
        return response as any
    }
)

export const { setUuid, setAuthTokens, setAuthStatus } = authSlice.actions

export default authSlice.reducer
