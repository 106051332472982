import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import authReducer from './actions/authSlice'
import profileReducer from './actions/profileSlice'
import subscriptionsReducer from './actions/subscriptionsSlice'
import versionReducer from './actions/versionSlice'
import downloadsReducer from './actions/downloadsSlice'
import projectsReducer from './actions/projectsSlice'
import { rootSaga } from './sagas/rootSaga'

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
    reducer: {
        version: versionReducer,
        auth: authReducer,
        profile: profileReducer,
        subscriptions: subscriptionsReducer,
        downloads: downloadsReducer,
        projects: projectsReducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(sagaMiddleware),
})

sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
