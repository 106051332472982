import { Accordion, AccordionDetails, AccordionSummary, styled } from '@mui/material'

export const ProductAccordion = styled(Accordion)(({ theme }) => ({
    // boxShadow: 'none',

    '&:before': {
        background: 'none',
    },

    '&.Mui-expanded': {
        boxShadow: `0 0 0 1px ${theme.palette.custom.mediumGrey.main}`,
        borderRadius: 10,
    },

    '.MuiAccordionSummary-root': {
        borderRadius: 10,
        height: 58,
        padding: '14px 33px',

        '.MuiAccordionSummary-content': {
            margin: 0,
        },

        '&.Mui-expanded': {
            minHeight: 58,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderBottom: 'solid 1px',
            borderColor: theme.palette.custom.mediumGrey.main,
        },
    },

    ':nth-of-type(odd)': {
        '.MuiAccordionSummary-root': {
            background: theme.palette.custom.whitesmoke.main,
        },
    },
}))

export const ProductAccordionSummary = styled(AccordionSummary)(({ theme }) => ({}))

export const ProductAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: '14px 33px',
    gap: 12,
    display: 'flex',
    flexDirection: 'column',

    '.MuiTableContainer-root': {
        boxShadow: `0 0 0 1px ${theme.palette.custom.whitesmoke.main}`,
        borderRadius: 10,
    },
}))

export const ProductVariantName = styled('div')(({ theme }) => ({
    flexGrow: 1,
}))

export const ProductVariantNote = styled('div')(({ theme }) => ({
    color: theme.palette.custom.mediumGrey.main,
    lineHeight: 1.25,
}))
