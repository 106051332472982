import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useCallback } from 'react'
import { signOut } from '../grpc/api'
import StandardButton from '../styles/button'
import StandardDialog, { DialogContainer } from '../styles/dialog'
import { useTranslation } from 'react-i18next'

const Dialog = ({ dialogType, title, message, open, handleOpen, handleConfirm }: ModalDialogProps) => {
    const { t } = useTranslation()

    const handleClose = useCallback(() => {
        if (handleOpen) {
            handleOpen(false)
        }
    }, [handleOpen])

    const handleConfirmation = useCallback(() => {
        if (handleConfirm) {
            handleConfirm()
        }
        handleClose()
    }, [handleConfirm, handleClose])

    const showCancel: boolean = dialogType !== 'alert' && dialogType !== 'sign-out'

    return (
        <StandardDialog
            open={open}
            onClose={dialogType !== 'permanent' ? handleClose : undefined}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'>
            <DialogContainer>
                <DialogTitle id='alert-dialog-title'>{t(title)}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>{t(message)}</DialogContentText>
                </DialogContent>
                {dialogType !== 'permanent' && (
                    <DialogActions>
                        {showCancel && (
                            <StandardButton variant='outlined' onClick={handleClose}>
                                {t('dialog.cancel')}
                            </StandardButton>
                        )}
                        {dialogType === 'alert' && (
                            <StandardButton onClick={handleClose}>{t('dialog.close')}</StandardButton>
                        )}
                        {dialogType === 'simple' && (
                            <StandardButton onClick={handleConfirmation}>{t('dialog.confirm')}</StandardButton>
                        )}
                        {dialogType === 'sign-out' && (
                            <StandardButton onClick={signOut}>{t('dialog.signIn')}</StandardButton>
                        )}
                    </DialogActions>
                )}
            </DialogContainer>
        </StandardDialog>
    )
}

export default Dialog
