import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    callActivateProject,
    callAddProjectUser,
    callArchivedProjects,
    callArchiveProject,
    callCreateProject,
    callProject,
    callProjects,
    callRemoveProject,
    callRemoveProjectUser,
    callUpdateProject,
    callUpdateProjectUserRole,
} from '../../grpc/api'

const initialState: ProjectsState = {
    projects: [],
    archivedProjects: [],
    status: 'not_loaded',
    error: null,
    selected: {
        project: {
            project: {
                projectid: '',
                name: 'project name',
                description: '',
                url: '',
            },
            usersList: [],
        },
        projectModified: {
            project: {
                projectid: '',
                name: 'project name',
                description: '',
                url: '',
            },
            usersList: [],
        },
        status: 'not_loaded',
        error: null,
    },
    createProjectStatus: 'not_loaded',
    createProjectError: null,
}

export const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        setSelectedProject: (state: ProjectsState, action: PayloadAction<ProjectInfo>): void => {
            state.selected.project = action.payload
            state.selected.projectModified = action.payload
        },
        setSelectedProjectModified: (state: ProjectsState, action: PayloadAction<ProjectInfo>): void => {
            state.selected.projectModified = action.payload
        },
        resetSelected: (state: ProjectsState): void => {
            state.selected = initialState.selected
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchProjects.pending, state => {
                state.status = 'loading'
            })
            .addCase(fetchProjects.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.projects = action.payload
                state.error = null
            })
            .addCase(fetchProjects.rejected, (state, action) => {
                state.status = 'failed'
                state.error = { ...action.error, code: Number.parseInt(action.error.code!) }
            })
            .addCase(fetchArchivedProjects.pending, state => {
                state.status = 'loading'
            })
            .addCase(fetchArchivedProjects.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.archivedProjects = action.payload
                state.error = null
            })
            .addCase(fetchArchivedProjects.rejected, (state, action) => {
                state.status = 'failed'
                state.error = { ...action.error, code: Number.parseInt(action.error.code!) }
            })
            .addCase(fetchProject.pending, state => {
                state.selected.status = 'loading'
            })
            .addCase(fetchProject.fulfilled, (state, action) => {
                state.selected.status = 'loaded'
                state.selected.project = action.payload
                state.selected.projectModified = action.payload
                state.selected.error = null
            })
            .addCase(fetchProject.rejected, (state, action) => {
                state.selected.status = 'failed'
                state.selected.error = { ...action.error, code: Number.parseInt(action.error.code!) }
            })
            .addCase(createProject.pending, state => {
                state.createProjectStatus = 'loading'
            })
            .addCase(createProject.fulfilled, state => {
                state.createProjectStatus = 'loaded'
                state.createProjectError = null
            })
            .addCase(createProject.rejected, (state, action) => {
                state.createProjectStatus = 'failed'
                state.createProjectError = { ...action.error, code: Number.parseInt(action.error.code!) }
            })
            .addCase(removeProject.pending, state => {
                state.selected.status = 'loading'
            })
            .addCase(removeProject.fulfilled, state => {
                state.selected.status = 'loaded'
                state.selected.error = null
            })
            .addCase(removeProject.rejected, (state, action) => {
                state.selected.status = 'failed'
                state.selected.error = { ...action.error, code: Number.parseInt(action.error.code!) }
            })
            .addCase(addProjectUser.pending, state => {
                state.selected.status = 'loading'
            })
            .addCase(addProjectUser.fulfilled, state => {
                state.selected.status = 'loaded'
                state.selected.error = null
            })
            .addCase(addProjectUser.rejected, (state, action) => {
                state.selected.status = 'failed'
                state.selected.error = { ...action.error, code: Number.parseInt(action.error.code!) }
            })
            .addCase(removeProjectUser.pending, state => {
                state.selected.status = 'loading'
            })
            .addCase(removeProjectUser.fulfilled, state => {
                state.selected.status = 'loaded'
                state.selected.error = null
            })
            .addCase(removeProjectUser.rejected, (state, action) => {
                state.selected.status = 'failed'
                state.selected.error = { ...action.error, code: Number.parseInt(action.error.code!) }
            })
            .addCase(updateProjectUserRole.pending, state => {
                state.selected.status = 'loading'
            })
            .addCase(updateProjectUserRole.fulfilled, state => {
                state.selected.status = 'loaded'
                state.selected.error = null
            })
            .addCase(updateProjectUserRole.rejected, (state, action) => {
                state.selected.status = 'failed'
                state.selected.error = { ...action.error, code: Number.parseInt(action.error.code!) }
            })
    },
})

export const fetchProjects = createAsyncThunk('projects/fetchProjects', async (): Promise<MyProject[]> => {
    return await callProjects()
})

export const fetchArchivedProjects = createAsyncThunk(
    'projects/fetchArchivedProjects',
    async (): Promise<MyProject[]> => {
        return await callArchivedProjects()
    }
)

export const fetchProject = createAsyncThunk(
    'projects/fetchProject',
    async (projectId: string): Promise<ProjectInfo> => {
        return await callProject(projectId)
    }
)

export const updateProject = createAsyncThunk(
    'projects/updateProject',
    async (data: Project): Promise<EmptyResponse> => {
        return await callUpdateProject(data)
    }
)

export const createProject = createAsyncThunk(
    'projects/createProject',
    async (data: CreateProject): Promise<EmptyResponse> => {
        return await callCreateProject(data)
    }
)

export const archiveProject = createAsyncThunk(
    'projects/archiveProject',
    async (projectId: string): Promise<EmptyResponse> => {
        return await callArchiveProject(projectId)
    }
)

export const activateProject = createAsyncThunk(
    'projects/activateProject',
    async (projectId: string): Promise<EmptyResponse> => {
        return await callActivateProject(projectId)
    }
)

export const removeProject = createAsyncThunk(
    'projects/removeProject',
    async (projectId: string): Promise<EmptyResponse> => {
        return await callRemoveProject(projectId)
    }
)

export const addProjectUser = createAsyncThunk(
    'projects/addProjectUser',
    async (data: AddProjectUserData): Promise<EmptyResponse> => {
        return await callAddProjectUser(data)
    }
)

export const removeProjectUser = createAsyncThunk(
    'projects/removeProjectUser',
    async (data: RemoveProjectUserData): Promise<EmptyResponse> => {
        return await callRemoveProjectUser(data)
    }
)

export const updateProjectUserRole = createAsyncThunk(
    'projects/updateProjectUserRole',
    async (data: UpdateProjectUserRole): Promise<EmptyResponse> => {
        return await callUpdateProjectUserRole(data)
    }
)

export const { setSelectedProject, setSelectedProjectModified, resetSelected } = projectsSlice.actions

export default projectsSlice.reducer
