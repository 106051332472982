import { styled } from '@mui/material'
import { FlexColumn, FlexColumnCenter } from './flex'

export const SectionContent = styled(FlexColumn)({
    padding: '86px 48px',
    maxHeight: 'calc(100vh - (86px * 2))',
    overflowY: 'auto',
    flexGrow: 1,
})

export const SectionContentCentered = styled(FlexColumnCenter)({
    padding: '238px 48px',
    maxHeight: 'calc(100vh - (238px * 2))',
    overflowY: 'auto',
    flexGrow: 1,
})
