import { PersonRounded } from '@mui/icons-material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { fetchProfile, setProfileStatus, updateProfile } from '../../redux/actions/profileSlice'
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks'
import { selectProfile, selectProfileError, selectProfileStatus } from '../../redux/selectors/profile-selector'
import StandardButton from '../../styles/button'
import { ProfileButtons, ProfileContainer, ProfileForm, ProfileHeader } from '../../styles/profile'
import { SectionContentCentered } from '../../styles/section-content'
import {
    SectionSidebarHeader,
    SectionSidebarHeaderSkeleton,
    SectionSidebarSubheader,
    SectionSidebarSubheader2,
    SectionSidebarSubheader2Skeleton,
    SectionSidebarSubheaderSkeleton,
} from '../../styles/section-sidebar'
import { PlainTextField, TextFieldLabel, TextFieldSkeleton } from '../../styles/textfield'
import { getName, userTypeToString } from '../../utils/utils'
import { StandardAlert } from '../../styles/alert'
import StandardSnackbar from '../../styles/snackbar'
import { AlertColor, FormControl, MenuItem, SelectChangeEvent } from '@mui/material'
import { PlainSelect } from '../../styles/select'
import StandardInputLabel from '../../styles/label'
import countries from '../../assets/countries.json'
import { useTranslation } from 'react-i18next'
import { RpcError } from 'grpc-web'

const ProfileSection = () => {
    const dispatch = useAppDispatch()
    const profile: Profile = useAppSelector(selectProfile)
    const [updatedProfile, setUpdatedProfile] = useState<Profile>(profile)
    const status: LoadStatus = useAppSelector(selectProfileStatus)
    const error: RpcError = useAppSelector(selectProfileError)
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const { t } = useTranslation()

    useEffect((): void => {
        if (status === 'not_loaded') {
            dispatch(fetchProfile())
        } else if (status === 'success') {
            setErrorMessage('')
            setShowSnackbar(true)
            dispatch(setProfileStatus('loaded'))
        } else if (status === 'failed') {
            setErrorMessage(error.message.replace(/^Error: /, ''))
            setShowSnackbar(true)
        }
    }, [dispatch, status, error, t])

    useEffect((): void => {
        setUpdatedProfile(profile)
    }, [profile])

    useEffect((): void => {
        document.title = `${t('profile.profile')} – ${process.env.REACT_APP_TITLE}`
    }, [t])

    const loaded: boolean = useMemo(
        (): boolean =>
            (status === 'loaded' || status === 'failed') &&
            Object.keys(profile).length > 0 &&
            Object.keys(updatedProfile).length > 0,
        [profile, updatedProfile, status]
    )

    const isUserChanged: boolean = useMemo(
        (): boolean => JSON.stringify(profile) !== JSON.stringify(updatedProfile),
        [profile, updatedProfile]
    )

    const showProvince: boolean = process.env.REACT_APP_SHOW_PROVINCE === 'true'

    const handleTextfield = useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (event.target.id === 'salutation') {
                setUpdatedProfile({ ...updatedProfile, salutation: event.target.value })
            } else if (event.target.id === 'firstname') {
                setUpdatedProfile({ ...updatedProfile, firstname: event.target.value })
            } else if (event.target.id === 'lastname') {
                setUpdatedProfile({ ...updatedProfile, lastname: event.target.value })
            } else if (event.target.id === 'email') {
                setUpdatedProfile({ ...updatedProfile, email: event.target.value })
            } else if (event.target.id === 'address.street') {
                setUpdatedProfile({
                    ...updatedProfile,
                    address: { ...updatedProfile.address, street: event.target.value },
                })
            } else if (event.target.id === 'address.postalcode') {
                setUpdatedProfile({
                    ...updatedProfile,
                    address: { ...updatedProfile.address, postalcode: event.target.value },
                })
            } else if (event.target.id === 'address.city') {
                setUpdatedProfile({
                    ...updatedProfile,
                    address: { ...updatedProfile.address, city: event.target.value },
                })
            } else if (event.target.id === 'address.province') {
                setUpdatedProfile({
                    ...updatedProfile,
                    address: { ...updatedProfile.address, province: event.target.value },
                })
            } else if (event.target.id === 'address.country') {
                setUpdatedProfile({
                    ...updatedProfile,
                    address: { ...updatedProfile.address, country: event.target.value },
                })
            }
        },
        [updatedProfile, setUpdatedProfile]
    )

    const handleCountryChange = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            setUpdatedProfile({
                ...updatedProfile,
                address: { ...updatedProfile.address, countrycode: event.target.value as string },
            })
        },
        [updatedProfile, setUpdatedProfile]
    )

    const handleCancel = useCallback((): void => {
        setUpdatedProfile(profile)
    }, [setUpdatedProfile, profile])

    const handleSave = useCallback((): void => {
        dispatch(updateProfile(updatedProfile))
    }, [dispatch, updatedProfile])

    const alertText = useMemo((): string => {
        if (errorMessage) {
            return t(errorMessage)
        }
        return t('profile.alert.profileSuccess')
    }, [errorMessage, t])

    const alertSeverity = useMemo((): AlertColor => {
        return errorMessage ? 'error' : 'success'
    }, [errorMessage])

    const handleCloseSnackbar = useCallback(() => {
        setShowSnackbar(false)
    }, [setShowSnackbar])

    return (
        <SectionContentCentered>
            <title>{t('profile.profile')}</title>
            <ProfileContainer>
                {!loaded && (
                    <>
                        <ProfileHeader>
                            <PersonRounded />
                            <div>
                                <SectionSidebarHeaderSkeleton />
                                <SectionSidebarSubheaderSkeleton />
                                <SectionSidebarSubheader2Skeleton />
                            </div>
                        </ProfileHeader>
                        <ProfileForm>
                            <TextFieldLabel>{t('profile.salutation')}</TextFieldLabel>
                            <TextFieldSkeleton />
                            <TextFieldLabel>{t('profile.firstName')}</TextFieldLabel>
                            <TextFieldSkeleton />
                            <TextFieldLabel>{t('profile.lastName')}</TextFieldLabel>
                            <TextFieldSkeleton />
                            <TextFieldLabel>{t('profile.street')}</TextFieldLabel>
                            <TextFieldSkeleton />
                            <TextFieldLabel>{t('profile.postalCode')}</TextFieldLabel>
                            <TextFieldSkeleton />
                            <TextFieldLabel>{t('profile.city')}</TextFieldLabel>
                            <TextFieldSkeleton />
                            {showProvince && (
                                <>
                                    <TextFieldLabel>{t('profile.province')}</TextFieldLabel>
                                    <TextFieldSkeleton />
                                </>
                            )}
                            <TextFieldLabel>{t('profile.country')}</TextFieldLabel>
                            <TextFieldSkeleton />
                        </ProfileForm>
                    </>
                )}
                {loaded && (
                    <>
                        <ProfileHeader>
                            <PersonRounded />
                            <div>
                                <SectionSidebarHeader>{getName(profile)}</SectionSidebarHeader>
                                <SectionSidebarSubheader>{profile.email}</SectionSidebarSubheader>
                                <SectionSidebarSubheader2>
                                    {t(`profile.${userTypeToString(profile)}`)}
                                </SectionSidebarSubheader2>
                            </div>
                        </ProfileHeader>
                        <ProfileForm>
                            <PlainTextField
                                id='salutation'
                                label={t('profile.salutation')}
                                value={updatedProfile.salutation}
                                variant='standard'
                                onChange={handleTextfield}
                            />
                            <PlainTextField
                                id='firstname'
                                label={t('profile.firstName')}
                                value={updatedProfile.firstname}
                                variant='standard'
                                onChange={handleTextfield}
                            />
                            <PlainTextField
                                id='lastname'
                                label={t('profile.lastName')}
                                value={updatedProfile.lastname}
                                variant='standard'
                                onChange={handleTextfield}
                            />

                            <PlainTextField
                                id='address.street'
                                label={t('profile.street')}
                                value={updatedProfile.address.street}
                                variant='standard'
                                onChange={handleTextfield}
                            />
                            <PlainTextField
                                id='address.postalcode'
                                label={t('profile.postalCode')}
                                value={updatedProfile.address.postalcode}
                                variant='standard'
                                onChange={handleTextfield}
                            />
                            <PlainTextField
                                id='address.city'
                                label={t('profile.city')}
                                value={updatedProfile.address.city}
                                variant='standard'
                                onChange={handleTextfield}
                            />
                            {showProvince && (
                                <PlainTextField
                                    id='address.province'
                                    label={t('profile.province')}
                                    value={updatedProfile.address.province}
                                    variant='standard'
                                    onChange={handleTextfield}
                                />
                            )}
                            <FormControl variant='standard'>
                                <StandardInputLabel id='role-label'>{t('profile.country')}</StandardInputLabel>
                                <PlainSelect
                                    id='address.country'
                                    labelId='country-label'
                                    label={t('profile.country')}
                                    value={updatedProfile.address.countrycode}
                                    displayEmpty
                                    onChange={handleCountryChange}>
                                    <MenuItem value=''>
                                        <em>{t('profile.selectCountry')}</em>
                                    </MenuItem>
                                    {countries.map(country => (
                                        <MenuItem key={`country-${country.code}`} value={country.code}>
                                            {t(`country.${country.code}`)}
                                        </MenuItem>
                                    ))}
                                </PlainSelect>
                            </FormControl>
                            <ProfileButtons>
                                <StandardButton variant='outlined' onClick={handleCancel} disabled={!isUserChanged}>
                                    {t('profile.cancel')}
                                </StandardButton>
                                <StandardButton onClick={handleSave} disabled={!isUserChanged}>
                                    {t('profile.save')}
                                </StandardButton>
                            </ProfileButtons>
                        </ProfileForm>
                    </>
                )}
            </ProfileContainer>
            <StandardSnackbar open={showSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <StandardAlert onClose={handleCloseSnackbar} severity={alertSeverity}>
                    {alertText}
                </StandardAlert>
            </StandardSnackbar>
        </SectionContentCentered>
    )
}

export default ProfileSection
