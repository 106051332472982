import { alpha, Skeleton, styled } from '@mui/material'
import { FlexColumn, FlexRow } from './flex'
import { Header } from './header'

export const SectionSidebarContainer = styled(FlexRow)(({ theme }) => ({
    backgroundColor: theme.palette.custom.whitesmoke.main,
    width: 400,
    height: '100vh',
    boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.5)',
    zIndex: theme.zIndex.appBar,
}))

export const SectionSidebar = styled(FlexColumn)({
    margin: '110px 79px 42px',
})

export const SectionSidebarHeader = styled(Header)({
    fontSize: 24,
    fontWeight: 400,
    lineHeight: 1.25,
})

export const SectionSidebarHeaderSkeleton = styled(Skeleton)({
    fontSize: 24,
    lineHeight: 1.25,
    margin: 0,
    width: 200,
})

export const SectionSidebarSubheader = styled('div')({
    fontSize: 16,
    // lineHeight: 1.875,
})

export const SectionSidebarSubheaderSkeleton = styled(Skeleton)({
    fontSize: 16,
    // lineHeight: 1.875,
    width: 128,
})

export const SectionSidebarSubheader2 = styled(FlexRow)(({ theme }) => ({
    fontSize: 16,
    lineHeight: 1.875,
    color: theme.palette.custom.darkGrey.main,
}))

export const SectionSidebarSubheader2Skeleton = styled(Skeleton)({
    fontSize: 16,
    width: 64,
})

export const SectionSidebarMenu = styled(FlexColumn)({
    margin: 0,
    marginTop: 38,
    width: 241,
    padding: 0,
    flexGrow: 1,
})

export const SectionSidebarMenuItem = styled(FlexRow)(({ theme }) => ({
    a: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        height: 40,
        marginBottom: 17,
        marginLeft: -79,
        marginRight: -80,
        padding: '0 79px',
        color: theme.palette.text.primary,
        textDecoration: 'none',

        span: {
            flexGrow: 1,
        },

        '&.active': {
            backgroundColor: alpha(theme.palette.custom.blue.main, 0.2),
        },
    },
}))

export const SectionSidebarButton = styled(FlexRow)({
    justifyContent: 'flex-end',
})
