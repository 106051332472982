import { useRouteError } from 'react-router-dom'
import { Header, Subheader } from '../styles/header'
import { PageCentered } from '../styles/page'
import { useTranslation } from 'react-i18next'

const Error: React.FC = () => {
    const error: Error = useRouteError() as Error
    const { t } = useTranslation()
    return (
        <PageCentered>
            <Header>{t('error.genericTitle')}</Header>
            <Subheader>{t(error.message) || t('error.genericMessage')}</Subheader>
        </PageCentered>
    )
}

export default Error
