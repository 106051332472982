import { styled, Dialog, alpha, Drawer } from '@mui/material'
import { FlexColumn } from './flex'

const StandardDialog = styled(Dialog)(({ theme }) => ({
    '.MuiModal-backdrop': {
        backgroundColor: alpha(theme.palette.common.white, 0.65),
    },

    '.MuiDialog-paper': {
        minWidth: 600,
        minHeight: 326,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 32,
        borderColor: theme.palette.custom.lightGrey.main,
        borderWidth: 0.5,
        borderStyle: 'solid',
        boxShadow: `0 2px 4px 0 ${alpha(theme.palette.common.black, 0.23)}`,
    },

    h2: {
        fontSize: 24,
        lineHeight: 1.25,
    },

    p: {
        color: theme.palette.text.primary,
        lineHeight: 1.25,
        letterSpacing: -0.057143,
    },

    '.MuiButtonBase-root:not(:first-of-type)': {
        marginLeft: 30,
    },
}))

export const DialogContainer = styled(FlexColumn)(({ theme }) => ({}))

export const StandardDrawer = styled(Drawer)(({ theme }) => ({
    '.MuiModal-backdrop': {
        backgroundColor: alpha(theme.palette.common.white, 0.65),
    },

    '.MuiDrawer-paper': {
        boxShadow: `-5px 0 12px 0 ${alpha(theme.palette.common.black, 0.3)}`,
    },
}))

export default StandardDialog
