import { useCallback, useMemo, useState } from 'react'
import { TableSortLabel } from '@mui/material'
import {
    StandardTableContainer,
    StandardTable,
    StandardTableHead,
    StandardTableRow,
    StandardTableCell,
    StandardTableBody,
} from '../styles/table'
import { Header, Subheader } from '../styles/header'
import { useTranslation } from 'react-i18next'

const CustomTable = ({ title, columns, rows, error }: CustomTableProps) => {
    const { t } = useTranslation()

    const [sorting, setSorting] = useState<TableSorting>({
        column: columns[0].id,
        direction: 'desc',
    })

    const rowSort = useCallback(
        (a: RowData, b: RowData) => {
            for (let i = 0; i < columns.length; i++) {
                if (sorting.column === columns[i].id) {
                    if (a.cells[i] === b.cells[i]) {
                        return 0
                    } else {
                        const direction = sorting.direction === 'asc' ? 1 : -1
                        return a.cells[i] < b.cells[i] ? direction : -direction
                    }
                }
            }
            return -1
        },
        [sorting, columns]
    )

    const sortedRows = useMemo((): RowData[] => {
        return rows.sort(rowSort)
    }, [rows, rowSort])

    const isColumnActive = useCallback(
        (id: string): boolean | undefined => {
            return sorting.column === id
        },
        [sorting]
    )

    const handleChangeSorting = useCallback(
        (event: React.PointerEvent<HTMLSpanElement>) => {
            const id = (event.target as HTMLSpanElement).id
            if (isColumnActive(id)) {
                if (sorting.direction === 'asc') {
                    setSorting({ ...sorting, direction: 'desc' })
                } else {
                    setSorting({ ...sorting, direction: 'asc' })
                }
            } else {
                setSorting({ column: id, direction: 'desc' })
            }
        },
        [sorting, isColumnActive]
    )

    const getColumnDirection = useCallback(
        (id: string) => {
            if (isColumnActive(id)) {
                return sorting.direction
            }
            return 'desc'
        },
        [sorting, isColumnActive]
    )

    return (
        <StandardTableContainer>
            {title && <Header>{title}</Header>}
            {error && <Subheader>{t(error.message)}</Subheader>}
            {!rows.length && !error && <Subheader>{t('table.noElements')}</Subheader>}
            {!!rows.length && (
                <StandardTable>
                    <StandardTableHead>
                        <StandardTableRow>
                            {columns.map(column => (
                                <StandardTableCell key={column.id} align={column.id === 'seats' ? 'center' : 'left'}>
                                    <TableSortLabel
                                        active={isColumnActive(column.id)}
                                        direction={getColumnDirection(column.id)}
                                        id={column.id}
                                        onClick={handleChangeSorting}>
                                        {column.name}
                                    </TableSortLabel>
                                </StandardTableCell>
                            ))}
                        </StandardTableRow>
                    </StandardTableHead>
                    <StandardTableBody>
                        {sortedRows.map(row => (
                            <StandardTableRow key={row.id}>
                                {row.cells.map((cell, i) => (
                                    <StandardTableCell
                                        key={`${row.id}-${columns[i].id}`}
                                        align={columns[i].id === 'seats' ? 'center' : 'left'}>
                                        {cell as any}
                                    </StandardTableCell>
                                ))}
                            </StandardTableRow>
                        ))}
                    </StandardTableBody>
                </StandardTable>
            )}
        </StandardTableContainer>
    )
}

export default CustomTable
