import { CircularProgress } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks'
import { Header, HeaderDivider, Subheader } from '../../styles/header'
import { SectionContent } from '../../styles/section-content'
import { selectProducts, selectProductsStatus } from '../../redux/selectors/downloads-selector'
import { fetchDownloads } from '../../redux/actions/downloadsSlice'
import {
    ProductAccordion,
    ProductAccordionDetails,
    ProductAccordionSummary,
    ProductVariantNote,
} from '../../styles/downloads'
import { ExpandMore } from '@mui/icons-material'
import ReactMarkdown from 'react-markdown'
import { SpacingM } from '../../styles/spacing'
import {
    StandardTable,
    StandardTableHead,
    StandardTableRow,
    StandardTableCell,
    StandardTableBody,
    StandardTableContainer,
} from '../../styles/table'
import { FitStandardButton } from '../../styles/button'
import { useTranslation } from 'react-i18next'
import { TextLink } from '../../styles/links'

const DownloadsSection = () => {
    const dispatch = useAppDispatch()
    const products: ProductState[] = useAppSelector(selectProducts)
    const status: LoadStatus = useAppSelector(selectProductsStatus)
    const { t } = useTranslation()

    useEffect(() => {
        if (status === 'not_loaded') {
            dispatch(fetchDownloads())
        }
    }, [dispatch, status])

    const loaded: boolean = useMemo((): boolean => status === 'loaded' || status === 'failed', [status])

    useEffect((): void => {
        document.title = `${t('subscriptions.downloads')} – ${process.env.REACT_APP_TITLE}`
    }, [t])

    const versionNumber = (version: ProductVersion): string => {
        let versionString: string = version.major.toString()
        if (version.minor !== undefined) {
            versionString += `.${version.minor}`
        }
        if (version.patch !== undefined) {
            versionString += `.${version.patch}`
        }
        if (version.revision !== undefined && version.revision.length > 0) {
            versionString += ` (${version.revision})`
        }
        return versionString
    }

    const productId = (product: ProductState, version: ProductVersion): string => {
        return `${product.name}-${versionNumber(version)}`
    }

    const hasExtras = (version: ProductVersions): boolean => {
        for (const variant of version.variants) {
            if (variant.extras?.length) {
                return true
            }
        }
        return false
    }

    return (
        <SectionContent>
            <Header>{t('subscriptions.downloads')}</Header>
            <HeaderDivider />
            <Subheader>{t('subscriptions.downloadsDescription')}</Subheader>
            <SpacingM />
            <FitStandardButton href='https://www.aimsun.com/aimsun-next-installation-guide/'>
                {t('subscriptions.installationGuide')}
            </FitStandardButton>
            <SpacingM />
            {!loaded && <CircularProgress color='inherit' />}
            {loaded &&
                products.map(product =>
                    product.versions.map(version => (
                        <ProductAccordion key={productId(product, version.version)} elevation={0}>
                            <ProductAccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls={productId(product, version.version)}
                                id={productId(product, version.version)}>
                                {product.name} {versionNumber(version.version)}
                            </ProductAccordionSummary>
                            <ProductAccordionDetails>
                                <StandardTableContainer>
                                    <StandardTable aria-label='simple table'>
                                        <StandardTableBody>
                                            {version.variants.map(variant => (
                                                <StandardTableRow key={variant.name}>
                                                    <StandardTableCell>
                                                        {variant.name}
                                                        {variant.note && (
                                                            <ProductVariantNote>
                                                                <ReactMarkdown>{variant.note}</ReactMarkdown>
                                                            </ProductVariantNote>
                                                        )}
                                                    </StandardTableCell>
                                                    <StandardTableCell align='right'>
                                                        <TextLink href={variant.link} target='_blank' rel='noreferrer'>
                                                            {t('subscriptions.download')}
                                                        </TextLink>
                                                    </StandardTableCell>
                                                </StandardTableRow>
                                            ))}
                                        </StandardTableBody>
                                    </StandardTable>
                                </StandardTableContainer>
                                {hasExtras(version) && (
                                    <StandardTableContainer>
                                        <StandardTable aria-label='simple table'>
                                            <StandardTableHead>
                                                <StandardTableRow>
                                                    <StandardTableCell colSpan={2}>
                                                        {t('subscriptions.extras')}
                                                    </StandardTableCell>
                                                </StandardTableRow>
                                            </StandardTableHead>
                                            <StandardTableBody>
                                                {version.variants.map(variant =>
                                                    variant.extras?.map(extra => (
                                                        <StandardTableRow key={extra.name}>
                                                            <StandardTableCell>
                                                                {extra.name} ({variant.os})
                                                            </StandardTableCell>
                                                            <StandardTableCell align='right'>
                                                                <TextLink
                                                                    href={variant.link}
                                                                    target='_blank'
                                                                    rel='noreferrer'>
                                                                    {t('subscriptions.download')}
                                                                </TextLink>
                                                            </StandardTableCell>
                                                        </StandardTableRow>
                                                    ))
                                                )}
                                            </StandardTableBody>
                                        </StandardTable>
                                    </StandardTableContainer>
                                )}
                            </ProductAccordionDetails>
                        </ProductAccordion>
                    ))
                )}
        </SectionContent>
    )
}

export default DownloadsSection
