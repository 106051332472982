export const UnableToAuthenticateDialog: ModalDialogBaseProps = {
    dialogType: 'sign-out',
    title: 'dialog.unableToAuthenticateTitle',
    message: 'dialog.unableToAuthenticateMessage',
}

export const ConfirmSignOutDialog: ModalDialogBaseProps = {
    dialogType: 'simple',
    title: 'dialog.confirmSignOutTitle',
    message: 'dialog.confirmSignOutMessage',
}

export const ConfirmArchiveProjectDialog: ModalDialogBaseProps = {
    dialogType: 'simple',
    title: 'dialog.confirmArchiveProjectTitle',
    message: 'dialog.confirmArchiveProjectMessage',
}

export const ConfirmRemoveProjectDialog: ModalDialogBaseProps = {
    dialogType: 'simple',
    title: 'dialog.confirmRemoveProjectTitle',
    message: 'dialog.confirmRemoveProjectMessage',
}

export const ServiceUnavailableDialog: ModalDialogBaseProps = {
    dialogType: 'alert',
    title: 'dialog.serviceUnavailableTitle',
    message: 'dialog.serviceUnavailableMessage',
}
