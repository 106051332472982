import { KeyboardArrowRightRounded } from '@mui/icons-material'
import { NavLink } from 'react-router-dom'
import {
    SectionSidebar,
    SectionSidebarContainer,
    SectionSidebarHeader,
    SectionSidebarMenu,
    SectionSidebarMenuItem,
} from '../styles/section-sidebar'
import { useTranslation } from 'react-i18next'

const SectionSidebarSubscriptions = () => {
    const { t } = useTranslation()

    return (
        <SectionSidebarContainer>
            <SectionSidebar>
                <SectionSidebarHeader>{t('subscriptions.subscriptions')}</SectionSidebarHeader>
                <SectionSidebarMenu>
                    <SectionSidebarMenuItem>
                        <NavLink to='my-subscriptions'>
                            <span>{t('subscriptions.mySubscriptions')}</span>
                            <KeyboardArrowRightRounded />
                        </NavLink>
                    </SectionSidebarMenuItem>
                    <SectionSidebarMenuItem>
                        <NavLink to='downloads'>
                            <span>{t('subscriptions.downloads')}</span>
                            <KeyboardArrowRightRounded />
                        </NavLink>
                    </SectionSidebarMenuItem>
                </SectionSidebarMenu>
            </SectionSidebar>
        </SectionSidebarContainer>
    )
}

export default SectionSidebarSubscriptions
